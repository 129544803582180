/* eslint-disable no-unused-vars */
import server from "../../api/server";

const state = {
};
const getters = {

}
const actions = {
    async find({commit},id){
        return new Promise((resolve, reject) => {
			console.log(id);
            server.post('/administrator/notifications/find', id)
            .then((res) => {
				resolve(res.data);
				console.log(res.data)
            })
            .catch(err => reject(err))
        });
    },
    async generate({commit}, content){
        return new Promise((resolve, reject) => {
            server.post('/administrator/notifications/generate', content)
            .then((res) => {
				resolve(res);
            })
            .catch(err => reject(err))
        });
    },
};
const mutations = {
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}