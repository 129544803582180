import server from "../../api/server";

const state = {
    title : null,
    html : null,
    type : null
};
const getters = {

}
const actions = {
    async create({commit}, data){
        commit('UPDATE_WEFTS', data)
        return new Promise((resolve, reject) => {
            server.post('administrator/wefts/create', data)
            .then((res) => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    },
    async find({commit}, data){
        commit('UPDATE_TYPE', data);
        return new Promise((resolve, reject) => {
            server.post('administrator/wefts/find', data)
            .then((res) => {
                console.log(res);
                resolve(res.data);
            })
            .catch(err => reject(err));
        });
    }, 
    async update({commit}, data){
        commit('UPDATE_TYPE', data);
        return new Promise((resolve, reject) => {
            server.post('administrator/wefts/update', data)
            .then((res) => {
                console.log(res);
                resolve(res.data);
            })
            .catch(err => reject(err));
        });
    }, 
    async delete({commit}, data){
        commit('UPDATE_TYPE', data);
        return new Promise((resolve, reject) => {
            server.post('administrator/wefts/delete', data)
            .then((res) => {
                console.log(res);
                resolve(res.data);
            })
            .catch(err => reject(err));
        });
    } 
};
const mutations = {
    UPDATE_WEFTS(state, data){
        state.title = data.title;
        state.html = data.html;
    },
    UPDATE_TYPE(state, data){
        state.type = data.type;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}