import server from '../../api/server';
const state = {
    declarant : {
        name : null,
        street : null,
        city : null,
        phone_number : null,
        zipcode : null,
        email : null
    },
    construction_information : {
        nature_operation : null,
        street : null,
        city : null,
        zipcode : null,
        manufacturer_name : null,
        first_owner_name : null,
        settlement_date_opening_site : null,
        receipt_work_date : null,
        reservations : null,
        occupant_name : null,
        occupant_state : null,
        occupant_tel : null
    },
    renseignements_sinister : {
        date : null,
        perfect_achievement : null,
        images : [],
        cracks : {
            location : null,
            importance : null, 
            infiltrations  : null,
            falling_materials : null
        },
        infiltrations : {
            location : null,
            importance : null, 
            origin : null,
        },
        other : [],
        garantees : null,
    },
    sign: {
        check : null,
        made_in : null,
        date : null,
    },
    contract_number : null,
    contract_id : null
};
const actions = {
    async addDeclarant({commit}, data){
        commit('UPDATE_DECLARANT', data)
    },
    async addConstructionInformation({commit}, data){
        commit('UPDATE_CONSTRUCTION', data)
    },
    async addSinisterDetails({commit}, data){
        commit('UPDATE_SINISTER', data);
    },
    async addSign({commit}, data){
        commit('UPDATE_SIGN', data);
    },
    async createSinister({state,rootGetters}){
        return new Promise((resolve, reject) => {
            console.log('toto');
            state.contract_number = rootGetters['contract/getContractNumber'];
            state.contract_id = rootGetters['contract/getContractID'];
            server.post('/sinister/create', state )
            .then( (res) => {
                resolve(res.data);
            })
            .catch(err => reject(err));
        });
    }
};
const mutations = {
    UPDATE_DECLARANT(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.declarant[key] = value;
        }
    },
    UPDATE_CONSTRUCTION(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.construction_information[key] = value;
        }
    },
    UPDATE_SINISTER(state, data){
        console.log(data);
        for (const [key, value] of Object.entries(data)) {
            state.renseignements_sinister[key] = value;
        }
    },
    UPDATE_SIGN(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.sign[key] = value;
        }
    },
};

export default{
    namespaced: true,
    state,
    actions,
    mutations,
}