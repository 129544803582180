import server from "../../api/server";

const state = {
    usersList : {},
    createUser : {},
    updateUser : {},
    ID : null,
};
const getters = {

}
const actions = {
    async find({commit}){
        return new Promise((resolve, reject) => {
            server.get('/administrator/users/find')
            .then((res) => {
                resolve(res.data);
                commit('UPDATE_USERS', res.data);
            })
            .catch(err => reject(err));
        });
    },
    async create({commit}, data){
        commit('UPDATE_CREATE_USER', data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/users/create', data)
            .then(() => {
                resolve(true);
            })
            .catch(err => reject(err));
        });
    },
    async delete({commit}, data){
        commit('UPDATE_ID_USER', data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/users/delete', {id : data})
            .then(() => {
                resolve(true);
            })
            .catch(err => reject(err));
        });
    },
    async resetPassword({commit}, data){
        commit('UPDATE_ID_USER', data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/users/password', {id : data})
            .then(() => {
                resolve(true);
            })
            .catch(err => reject(err));
        });
    },
    async update({commit}, data){
        commit('UPDATE_USER', data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/users/update', data)
            .then(() => {
                resolve(true);
            })
            .catch(err => reject(err));
        });
    },
};
const mutations = {
    UPDATE_USERS(state,data){
        data.forEach((element,index) => {
            let name = element.lastname + ' ' + element.firstname;
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    
            let initials = [...name.matchAll(rgx)] || [];
    
            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();
    
            data[index].initials = initials;
        });
        state.usersList = data;
    },
    UPDATE_CREATE_USER(state,data){
        state.createUser = data;
    },
    UPDATE_ID_USER(state,data){
        state.ID = data;
    },
    UPDATE_USER(state,data){
        state.updateUser = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}