import server from "../../api/server";

const state = {

};
const getters = {

}
const actions = {
    async findDepartments(){
        return new Promise((resolve, reject) => {
            server.get('/administrator/departments/find')
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    async findExperts(){
        return new Promise((resolve, reject) => {
            server.get('/administrator/experts/find')
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    // eslint-disable-next-line no-unused-vars
    async findExpertsDep({commit}, id){
        return new Promise((resolve, reject) => {
            server.post('/administrator/experts/find/dep', id)
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    // eslint-disable-next-line no-unused-vars
    async findExpertsReference({commit},id){
        return new Promise((resolve, reject) => {
            server.post('/administrator/experts/find', {id})
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    // eslint-disable-next-line no-unused-vars
    async register({commit}, data){
        return new Promise((resolve, reject) => {
            server.post('/administrator/experts/register', data)
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
};
const mutations = {
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}