import server from '../../api/server';
const state = {
};
const getters = {

}
const actions = {
    async find(){
        console.log('toto');
        return new Promise((resolve, reject) => {
            server.get('/administrator/dashboard')
            .then((res) =>{
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }
};
const mutations = {

};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}