import Vuex from 'vuex';
import contract from './modules/contract';
import createSinistre from './modules/createSinistre';
import findSinister from './modules/findSinister';
import dashboardSinister from './modules/dashboardSinister';
import iban from './modules/iban';
import files from './modules/files';
import connexionAdministrator from './modules/connexionAdministrator';
import users from './modules/users';
import listing from './modules/listing';
import sinister from './modules/sinister';
import adminMessage from './modules/adminMessage';
import dashboardAdmin from './modules/dashboardAdmin';
import experts from './modules/experts';
import sav from './modules/sav';
import wefts from './modules/wefts';
import order from './modules/order';
import translator from './modules/translator';
import notifications from './modules/notifications';
import forgetPassword from './modules/forgetPassword';
// Create a new store instance.
const store = new Vuex.Store({
    modules: {
        contract,
        createSinistre,
        findSinister,
        dashboardSinister,
        iban,
        files,
        connexionAdministrator,
        users,
        listing,
        sinister,
        adminMessage,
        dashboardAdmin,
        experts,
        sav,
        wefts,
        order,
		translator,
		notifications,
		forgetPassword
    }
})
export default store;