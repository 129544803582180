import { createRouter, createWebHistory } from 'vue-router'
const vueCookie = require('vue-cookie');

const routes = [
  {
    path: '/assure',
    name: 'assured',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/Assure.vue')
  },
  {
    path: '/',
    name: 'assured-home',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/Assure.vue')
  },
//   {
//     path: '/test',
//     name: 'test',
//     component: () => import(/* webpackChunkName: "about" */ '../components/administrator/ListingTry.vue')
//   },
  {
    path: '/assure/declaration',
    name: 'declaration',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/Declaration.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = localStorage.getItem('contract');
        token ? next() : next('/assure')
    }
  },
  {
    path: '/assure/dashboard',
    name: 'assured-dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/dashboard/Dashboard.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = localStorage.getItem('jwt');
        token ? next() : next('/assure')
    }
  },
  {
    path: '/assure/dashboard/iban',
    name: 'assured-dashboard-iban',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/dashboard/Iban.vue'),
        beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = localStorage.getItem('jwt');
        token ? next() : next('/assure')
    }
  },
  {
    path: '/assure/dashboard/fichiers',
    name: 'assured-dashboard-files',
    component: () => import(/* webpackChunkName: "about" */ '../views/assured/dashboard/Files.vue'),
        beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = localStorage.getItem('jwt');
        token ? next() : next('/assure')
    }
  },
  {
    path: '/gestionnaire/login',
    name: 'administrator-login',
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Login.vue')
  },
  {
    path: '/gestionnaire',
    name: 'administrator',
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Administrator.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = vueCookie.get('jwt');
        token ? next() : next('/gestionnaire/login')

    }
  },
  {
    path: '/gestionnaire/:page',
    name: 'administrator-page',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Administrator.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = vueCookie.get('jwt');
        token ? next() : next('/gestionnaire/login')

    }
  },
  {
    path: '/gestionnaire/:page/details/:id',
    name: 'administrator-page-id',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Administrator.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = vueCookie.get('jwt');
        token ? next() : next('/gestionnaire/login')

    }
  },
  {
    path: '/gestionnaire/sinistre/:newSinister',
    name: 'create-administrator',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Declaration.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = vueCookie.get('jwt');
        token ? next() : next('/gestionnaire/login')

    }
  },
  {
    path: '/gestionnaire/:page/:filter',
    name: 'filter-sinisters',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/Administrator.vue'),
    beforeEnter: function(to, from, next){
        console.log(to, from , next);
        const token = vueCookie.get('jwt');
        token ? next() : next('/gestionnaire/login')

    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
