import server from "../../api/server";

const state = {
};
const getters = {

}
const actions = {
    // eslint-disable-next-line no-unused-vars
    async create({commit}, data){
        return new Promise((resolve, reject) => {
            server.post('administrator/sinister/order', data)
            .then((res) => {
                console.log(res.data)
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    // eslint-disable-next-line no-unused-vars
    async findInformations({commit}, data){
        return new Promise((resolve, reject) => {
            server.post('administrator/sinister/order/find', data)
            .then((res) => {
                console.log(res.data)
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
};
const mutations = {
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}