/* eslint-disable no-unused-vars */
import server from '../../api/server';
const state = {
};
const getters = {

}
const actions = {
    async testEmail({commit}, data){
		console.log(data)
        return new Promise((resolve, reject) => {
            server.post('/sinister/forget/password', data)
            .then((res) =>{
                console.log(res);
                localStorage.setItem('jwt', res.data);
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }
};
const mutations = {
    UPDATE_REF(state,data){
        state.ref = data;
    },
    UPDATE_PASSWORD(state,data){
        state.password = data;
    }
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}