import server from '../../api/server';
var VueCookie = require('vue-cookie');
const state = {
};
const getters = {
}
const actions = {
    // eslint-disable-next-line no-empty-pattern
    async connexion({}, data ){
        return new Promise((resolve, reject) => {
            server.post('administrator/auth', data)
            .then((res) => {
                console.log(res);
                if(data.stayConnected){
                    // COOKIE
                    VueCookie.set('jwt', res.data.token, '1Y');                
                }
                else{
                    VueCookie.set('jwt', res.data.token, '10h');                
                }
                localStorage.setItem('name', res.data.name);
                resolve(true)
            })
            .catch(() => {
                reject(false);
            })
        });
    }
};
const mutations = {
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}