import server from '../../api/server';
const state = {
    declarant : {
        name : null,
        street : null,
        city : null,
        zipcode : null,
        phone_number : null,
    },
    construction : {
        nature_operation: null,
        street: null,
        city: null,
        zipcode: null,
        first_owner_name: null,
        manufacturer_name: null,
        settlement_date_opening_site: null,
        receipt_work_date: null,
        reservations: null,
        occupant_name: null,
        occupant_state: null,
        occupant_tel: null
    },
    sinister:{
        contract_number_do : null,
        date_created : null,
        management_code: null,
        sinister_reference : null,
        category : null
    },
    claim : {
        date_disorder: null,
        perfect_completion: null,
    },
	findExpert : null,
    disorders : {},
    attachments : {},
    iban : null,
    nbMessages : null
};
const getters = {

}
const actions = {
    async dataSinister({commit}){
        const token = localStorage.getItem('jwt');
        return new Promise((resolve, reject) => {
            server.post('/sinister/dashboard', {token})
            .then((res) =>{
                console.log(res.data.iban)
				console.log(res);
                resolve(res.data.sinister.category);
                commit('UPDATE_DECLARANT', res.data.declarant);
                commit('UPDATE_CONSTRUCTION', res.data.construction);
                commit('UPDATE_SINISTER', res.data.sinister);
                commit('UPDATE_CLAIM', res.data.claim);
                commit('UPDATE_DISORDERS', res.data.disorders);
                commit('UPDATE_ATTACHMENTS', res.data.attachments);
                commit('UPDATE_IBAN', res.data.iban);
                commit('UPDATE_MESSAGES', res.data.nbMessages);
                commit('UPDATE_EXPERT', res.data.findExpert);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }
};
const mutations = {
    UPDATE_DECLARANT(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.declarant[key] = value;
        }
    },
    UPDATE_CONSTRUCTION(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.construction[key] = value;
        }
    },
    UPDATE_SINISTER(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.sinister[key] = value;
        }
    },
    UPDATE_CLAIM(state, data){
        for (const [key, value] of Object.entries(data)) {
            state.claim[key] = value;
        }
    },
    UPDATE_DISORDERS(state, data){
        state.disorders = data;
    },
    UPDATE_ATTACHMENTS(state, data){
        state.attachments = data;
    },
    UPDATE_IBAN(state, data){
        state.iban = data;
    },
    UPDATE_MESSAGES(state, data){
        state.nbMessages = data;
    },
    UPDATE_EXPERT(state, data){
        state.findExpert = data;
    },
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
