import server from '../../api/server';

const state = {
    content : null,
    id_sinister : null,
};
const getters = {
}
const actions = {
    async send({commit}, data){
        commit('UPDATE_SINISTER', data.idSinister);
        return new Promise((resolve, reject) => {
			console.log('Store : ' ,data);
            server.post('/administrator/message/send', data)
            .then(() => {
                resolve(true)
            })
            .catch(err => reject(err))
        }) 
    },
    async find({commit}, data){
        commit('UPDATE_SINISTER', data.idSinister);
        return new Promise((resolve, reject) => {
            server.post('/administrator/message/find', data)
            .then((res) => {
                resolve(res.data)
            })
            .catch(err => reject(err))
        }) 
    },
};
const mutations = {
    UPDATE_SINISTER(state,data){
        state.id_sinister = data;
    }
};

export default{
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}