import server from "../../api/server";
const state = {
    id_sinister: null,
    site : null,
    claim:null,
    declarant: null,
    askFile: null,
    files : null,
    category : null,
};
const getters = {

}
const actions = {
    async findOne({commit}, id){
        commit('UPDATE_ID', id);
        return new Promise((resolve, reject) => {
            server.post('/administrator/sinister/details', {id})
            .then((res) => {
                commit('UPDATE_SITE', res.data.site);
                commit('UPDATE_CLAIM', res.data.claim);
                commit('UPDATE_DECLARANT', res.data.declarant);
                commit('UPDATE_FILES', res.data.files);
                commit('UPDATE_CATEGORY', res.data.category);

                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
    async update({commit}, data){
        commit('UPDATE_ID', 1);
        return new Promise((resolve, reject) => {
            server.post('/administrator/sinister/update', data)
            .then(() => {
                resolve(true)
            })
            .catch(err => reject(err))
        })
    },
    async askFile({commit}, data){
        console.log(data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/sinister/file/ask', {data})
            .then(() => {
                commit('UPDATE_ASK_FILE', data);
                resolve(true)
            })
            .catch(err => {
                reject(err);
            })
        });
    },
	// eslint-disable-next-line no-unused-vars
	async generatePdf({commit}, data){
		return new Promise((resolve, reject) => {
            server.post('/administrator/sinister/generate/pdf', data, {responseType: 'arraybuffer'})
            .then((res) => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err);
            })
        });
	}
};
const mutations = {
    UPDATE_ID(state,data){
        state.id_sinister = data;
    },
    UPDATE_SITE(state,data){
        state.site = data;
    },
    UPDATE_CLAIM(state,data){
        state.claim = data;
    },
    UPDATE_DECLARANT(state,data){
        state.declarant = data;
    },
    UPDATE_ASK_FILE(state,data){
        state.askFile = data;
    },
    UPDATE_FILES(state,data){
        state.files = data;
    },
    UPDATE_CATEGORY(state,data){
        state.category = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
