import server from "../../api/server";

const state = {
    contract_number : null,
    iban : null,
    create : null,
};
const getters = {

}
const actions = {
    async find({commit}){
        const token = localStorage.getItem('jwt');
        return new Promise((resolve, reject) => {
            server.post('/sinister/iban/find', {token})
            .then((res) => {
                resolve({iban : res.data.iban, id : res.data.id});
                commit('UPDATE_CONTRACT', res.data.contract_number);
                commit('UPDATE_IBAN', res.data.iban);
            })
            .catch(err => reject(err))
        });
    },
    async createIban({commit}, data){
        commit('UPDATE_IBAN', data);
        const token = localStorage.getItem('jwt');
        return new Promise((resolve, reject) => {
            server.post('/sinister/iban/create', {data, token})
            .then((res) => {
                resolve(res.data.iban);
            })
            .catch(err => reject(err))
        });
    }
};
const mutations = {
    UPDATE_CONTRACT(state,data){
        state.contract_number = data;
    },
    UPDATE_IBAN(state,data){
        state.iban = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}