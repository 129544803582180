import server from "../../api/server";

const state = {
    listing: null
};
const getters = {

}
const actions = {
    async find({commit}){
        return new Promise((resolve, reject) => {
            server.get('/administrator/listing/all', {})
            .then((res) => {
                console.log(res.data)
                commit('UPDATE_LISTING', res.data);
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },
};
const mutations = {
    UPDATE_LISTING(state,data){
        state.listing = data;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}