import server from "../../api/server";

const state = {

};
const getters = {

}
const actions = {
    // eslint-disable-next-line no-empty-pattern
    async create({}, data){
        console.log(data);
        return new Promise((resolve, reject) => {
            server.post('/administrator/sinister/sav', data)
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => reject(err))
        });
    },

};
const mutations = {
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}